import { FC, useCallback } from 'react';

import { MenuItem as MenuItemComponent } from '@vakantiesnl/components/src/__LEGACY__/atoms/MenuItem';
import { GTM, Link, MicroCopy } from '@vakantiesnl/types';

import css from './Menu.module.css';

type Props = {
	menuItems: Link[];
	microCopies: MicroCopy;
	toggled: boolean;
	closeMenu: VoidFunction;
	pageType: GTM.GTMPageType;
};

type MenuItemProps = Omit<Link, 'link'> & {
	href: string;
	toggled: boolean;
	microCopies: MicroCopy;
	closeMenu: VoidFunction;
	isExternal: boolean;
	pageType: GTM.GTMPageType;
};

const MenuItem: FC<MenuItemProps> = (props: MenuItemProps) => {
	const trackNavigationAction = useCallback((): void => {
		props.closeMenu();
		import('@vakantiesnl/services/src/gtm/common').then(({ track }) =>
			import('@vakantiesnl/services/src/gtm/navigation').then(({ headerNavigation }) => {
				track(headerNavigation(props.pageType, props.title, props.href));
			}),
		);
	}, [props]);

	return <MenuItemComponent {...props} onClick={trackNavigationAction} />;
};

export const Menu: FC<Props> = ({ menuItems, microCopies, toggled, closeMenu, pageType }) => (
	<div className={css.menuContainer} data-toggled={toggled ? 'toggled' : 'unToggled'}>
		<nav className={css.menu}>
			<ul className={css.mainMenuList}>
				{menuItems.map(({ title, link, target, isExternal }, key) => (
					<MenuItem
						target={target}
						title={title}
						href={link}
						key={`menuitem-${key}`}
						toggled={toggled}
						microCopies={microCopies}
						closeMenu={closeMenu}
						isExternal={isExternal}
						pageType={pageType}
					/>
				))}
			</ul>
		</nav>
	</div>
);
