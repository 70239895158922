import { FC } from 'react';

import { Content } from '@vakantiesnl/components/src/__LEGACY__/atoms/Content';
import { Heading } from '@vakantiesnl/components/src/__LEGACY__/atoms/Heading';
import { Link } from '@vakantiesnl/components/src/__LEGACY__/atoms/Link';
import { ContactBlock, USPBlock, Navigation } from '@vakantiesnl/components/src/__LEGACY__/molecules/Footer';
import { PartnersBlock } from '@vakantiesnl/components/src/molecules/PartnersBlock/PartnersBlock';
import ImageComponent from '@vakantiesnl/image-component';
import { ContentfulImageParams } from '@vakantiesnl/image-component/src/loaders/contentful';
import { ImageLink, Partner, Usp, MicroCopy, Link as LinkType, GTM } from '@vakantiesnl/types';
import { Asset } from 'contentful';

import css from './Footer.module.css';

type Props = {
	contactTitle: string;
	contactLinks: ImageLink[];
	usps: Usp[];
	logo: Asset;
	qualityMarks: Partner[];
	paymentMethods: Partner[];
	otherPartners: Partner[];
	navigation: LinkType[];
	microCopies: MicroCopy;
	pageType: GTM.GTMPageType;
};

const contentfulParams: ContentfulImageParams = { fm: 'webp' };

export const Footer: FC<Props> = ({ contactTitle, contactLinks, usps, logo, navigation, microCopies, pageType }) => {
	return (
		<>
			<footer className={css.footer}>
				<Content className={css.contactHeader}>
					<div>
						<Heading headingLevel="h4" className={css.contactHeading}>
							{contactTitle}
						</Heading>
						<ContactBlock contactLinks={contactLinks} />
					</div>
					<div>
						<Heading headingLevel="h4" className={css.contactHeading}>
							{microCopies['footer.uspHeader']}
						</Heading>
						<USPBlock usps={usps} />
					</div>
				</Content>
				<div className={css.footerBottom}>
					<Content>
						<div className={css.logoBlock}>
							{logo.fields.file.url && (
								<Link href="/" className={css.logoBlockHref}>
									<ImageComponent
										useLoader="Contentful"
										className={css.image}
										contentfulParams={contentfulParams}
										alt={logo.fields.title}
										src={logo.fields.file.url}
										fill={true}
										sizes="165px"
									/>
								</Link>
							)}
						</div>

						<Navigation links={navigation} pageType={pageType} />
					</Content>
				</div>
			</footer>
			<PartnersBlock />
		</>
	);
};
