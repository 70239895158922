import { FC } from 'react';

import { Content } from '@vakantiesnl/components/src/__LEGACY__/atoms/Content';
import { MenuContainer } from '@vakantiesnl/components/src/__LEGACY__/molecules/MenuContainer';
import { HeaderTopBar } from '@vakantiesnl/components/src/molecules/HeaderTopBar/HeaderTopBar';
import { Link, MicroCopy, GTM } from '@vakantiesnl/types';

import useStyles from './Header.styles';

type Props = {
	menuItems: Link[];
	subMenuItems: Link[];
	microCopies: MicroCopy;
	showTopBar?: boolean;
	pageType: GTM.GTMPageType;
};

export const Header: FC<Props> = ({ menuItems, microCopies, subMenuItems, showTopBar = true, pageType }) => {
	const { classes: styles } = useStyles();

	return (
		<>
			{showTopBar && <HeaderTopBar />}
			<div>
				<Content>
					<header className={styles.menuHeader}>
						<MenuContainer
							menuItems={menuItems}
							subMenuItems={subMenuItems}
							microCopies={microCopies}
							pageType={pageType}
						/>
					</header>
				</Content>
			</div>
		</>
	);
};
